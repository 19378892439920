import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
// import '@/assets/css/global.css'
// import '@/assets/css/theme/index.css'
// import './assets/gloable.css'
import httpRequest from '@/utils/httpRequest'
import '/node_modules/qweather-icons/font/qweather-icons.css'
import '@/assets/css/iconfont/iconfont.css'
import '@/assets/css/theme/index.css'
import BaiduMap from 'vue-baidu-map'

// 百度地图
Vue.use(BaiduMap, {
  // ak 是在百度地图开发者平台申请的密钥
  ak: 'wxyXaqYEce6mHLXkb5LBgGOGamp7vbEU'
})
// 挂载全局
Vue.prototype.$http = httpRequest // ajax请求方法
import request from "@/utils/request";

// main.js全局注册
import mavonEditor from 'mavon-editor'
import 'mavon-editor/dist/css/index.css'
// use
Vue.use(mavonEditor)

Vue.config.productionTip = false

Vue.use(ElementUI, { size: "mini" });

Vue.prototype.request=request
Vue.prototype.$baseUrl = process.env.VUE_APP_BASEURL

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
